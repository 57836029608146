<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box flexdc" style="height:100%">
              <div class="card-box">
                <div
                  class="card-item flexdc"
                  @click="doLook('/web/UnitStatistics', '10')"
                >
                  <span>机构总数</span>
                  <span>{{ mechanism.total || 0 }}</span>
                  <div class="df">
                    <span>年度新增</span>
                    <span>{{ mechanism.annualTotal || 0 }}</span>
                  </div>
                </div>
                <div
                  class="card-item flexdc"
                  @click="doLook('/web/UnitStatistics', '20')"
                >
                  <span>企业总数</span>
                  <span>{{ Company.total || 0 }}</span>
                  <div class="df">
                    <span>年度新增</span>
                    <span>{{ Company.annualTotal || 0 }}</span>
                  </div>
                </div>
                <div
                  class="card-item flexdc"
                  @click="doLook('/web/ClassTrainingStatistics')"
                >
                  <span>开班总数</span>
                  <span>{{ Class.total || 0 }}</span>
                  <div class="df">
                    <span>培训中</span>
                    <span>{{ Class.underwayTotal || 0 }}</span>
                  </div>
                </div>
                <div
                  class="card-item flexdc"
                  @click="doLook('/web/StudentLearningRecord')"
                >
                  <span>学员总人次</span>
                  <span>{{ Student.total || 0 }}</span>
                  <div class="df">
                    <span>已完成学习</span>
                    <span>{{ Student.completeTotal || 0 }}</span>
                  </div>
                </div>
                <div class="card-item flexdc">
                  <span>在线总人数</span>
                  <span>{{ Online.onlineNum || 0 }}</span>
                  <div class="df">
                    <span style="display:list-item"> </span>
                    <span> </span>
                  </div>
                </div>
              </div>
              <div  style="height: 80%;display: flex;flex-direction: column;">
                <h3 class="title" style="height:5%;min-height:7%">区域统计</h3>
                <div class="echerts-box" style="height:95%;min-height:93%">
                  <div style="height:100%">
                  <div class="btn-box" style="display:flex;justify-content: flex-end;">
                    <el-button
                      size="mini"
                      :class="this.stu == 'mechanism' ? 'bgc-bv' : 'active'"
                      @click="getCompanyEchart('mechanism')"
                      >机构总数</el-button
                    >
                    <el-button
                      size="mini"
                      :class="this.stu == 'company' ? 'bgc-bv' : 'active'"
                      @click="getCompanyEchart('company')"
                      >企业总数</el-button
                    >
                    <el-button
                      size="mini"
                      :class="this.stu == 'class' ? 'bgc-bv' : 'active'"
                      @click="getCompanyEchart('class')"
                      >开班总数</el-button
                    >
                    <el-button
                      size="mini"
                      :class="this.stu == 'student' ? 'bgc-bv' : 'active'"
                      @click="getCompanyEchart('student')"
                      >培训学员</el-button
                    >
                  </div>
                  <div
                    class="echarts flex1"
                    id="echart1"
                    style="height: 92%;min-height:89%
                        display: flex;
                        flex-direction: column;"
                    v-show="echartsShow"
                  ></div>
                  <div
                    style=" height:100%; flex:1;position:relative"
                    v-show="trues"
                  >
                    <div style="height:100%; display:flex;">
                      <img
                        src="@/assets/empty.png"
                        alt=""
                        style="width:300px; height:185px"
                        class="imgCenter"
                      />
                      <span class="span">暂无数据</span>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
let echart1 = {};
import LoginRecommend from "@/components/loginRecommend.vue";
export default {
  name: "SupervisionEnd/home",
  components: {
    LoginRecommend
  },
  data() {
    return {
      Student: {},
      Class: {},
      Company: {}, // 企业
      Online: {}, // 在线总人数
      mechanism: {}, // 机构
      hideEmpty: true,
      trues: false,
      echartsShow: true,
      stu:'mechanism',
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.initEchart();
    echart1 = this.$echarts.init(document.getElementById("echart1"));
    this.getCompanyEchart();
  },
  computed: {},
  methods: {
    init() {
      this.getStudent();
      this.getClass();
      this.getCompany();
      this.getOnline();
      this.getMechanism();
    },
    // 学员统计
    getStudent() {
      this.$post("/gov/dashboard/user").then((res) => {
        if (res.status == "0") {
          this.Student = res.data;
        }
      });
    },
    // 开班统计
    getClass() {
      this.$post("/gov/dashboard/project").then((res) => {
        if (res.status == "0") {
          this.Class = res.data;
        }
      });
    },
    // 企业统计
    getCompany() {
      this.$post("/gov/dashboard/company", { compType: "20" }).then((res) => {
        if (res.status == "0") {
          this.Company = res.data;
        }
      });
    },
    // 在线总人数
    getOnline() {
      this.$post("/gov/dashboard/user/online", { compType: "20" }).then((res) => {
        if (res.status == "0") {
          console.log(res.data);
          this.Online = res.data;
        }
      });
    },
    // 机构统计
    getMechanism() {
      this.$post("/gov/dashboard/company", { compType: "10" }).then((res) => {
        if (res.status == "0") {
          this.mechanism = res.data;
        }
      });
    },
    // 企业图表
    getCompanyEchart(stu = "mechanism") {
      this.stu  = stu || 'mechanism'
      let param = {};
      let tip = "";
      let url = "/company/bar";
      let path = "";
      if (stu == "student") {
        url = "/user/bar";
        tip = "学员总数";
        param = {};
        path = "/web/StudentLearningRecord";
      } else if (stu == "mechanism") {
        url = "/company/bar";
        tip = "机构总数";
        param = {
          compType: "10",
        };
        path = "/web/UnitStatistics";
      } else if (stu == "company") {
        url = "/company/bar";
        tip = "企业总数";
        param = {
          compType: "20",
        };
        path = "/web/UnitStatistics";
      } else {
        url = "/project/bar";
        tip = "开班总数";
        param = {};
        path = "/web/ClassTrainingStatistics";
      }
      this.$post("/gov/dashboard" + url, param).then((res) => {
        if (
          res.data.barArea.length != 0 ||
          res.data.barXAxis.length != 0 ||
          res.data.barDate.length != 0
        ) {
          this.initEchart(res.data, tip, path);
          this.trues = false;
          this.echartsShow = true;
        } else {
          this.trues = true;
          this.echartsShow = false;
          document
            .getElementById("echart1")
            .removeAttribute("_echarts_instance_");
        }
      });
    },
    initEchart(params, tip, path) {
      const that = this;
      const option = {
        color: ["#5c6be8"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: params.barXAxis,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: tip || "机构总数",
            type: "bar",
            barWidth: "45px",
            data: params.barDate,
          },
        ],
      };
      echart1.setOption(option);
      echart1.on("click", function(param) {
        let areaId = "";
        params.barArea.forEach((item, index) => {
          if (index == param.dataIndex) {
            areaId = item;
          }
        });
        if (tip == "机构总数") {
          param = "10";
        } else if (tip == "企业总数") {
          param = "20";
        }
        that.doLook(path, param, areaId);
      });
      // window.resize(function() {
      //   echart1.resize();
      // });
      window.addEventListener("resize", function () {
        echart1.resize();
      });
    },
    doLook(url, params, areaId) {
      this.$router.push({
        path: url,
        query: {
          params,
          areaId,
          refresh:true,
        },
      });
    },
  },
};
</script>
<style lang="less"></style>
<style lang="less" scope>
.card-box {
  margin-bottom: 10px;
  height: 20%;
      display: flex;
    overflow: hidden;
    justify-content: space-between;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 19%;
    // min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      // line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../../assets/first.png);
    background-size: 100% 100%;
  }
  & > :nth-child(2) {
    background-image: url(../../../assets/second.png);
    background-size: 100% 100%;
  }
  & > :nth-child(3) {
    background-image: url(../../../assets/third.png);
    background-size: 100% 100%;
  }
  & > :nth-child(4) {
    background-image: url(../../../assets/fourth.png);
    background-size: 100% 100%;
  }
  & > :nth-child(5) {
    background-image: url(../../../assets/five.png);
    background-size: 100% 100%;
  }
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  .btn-box {
    align-self: flex-end;
    padding: 10px;
    // margin-bottom: 10px;
    .el-button {
      &:focus {
        background-color: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
    .active {
      background-color: rgb(218, 221, 250);
      color: rgb(92, 107, 232);
      border-color: rgb(218, 221, 250);
    }
  }
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
</style>
